<template>
    <div
        v-if="user"
        class="user-row cursor-pointer"
        :class="classObject"
    >
        <user-link
            :uid="user.uid"
            class="avatar avatar-medium"
        >
            <img
                :src="user.photoURL"
                alt=""
            />
        </user-link>

        <div class="display-name flex flex-center">
            <user-link :uid="user.uid">
                {{ user.displayName }}
            </user-link>
            <transition name="fade">
                <span
                    v-if="hasAdminPermissions"
                    class="pill light mleft-xsmall"
                >Admin</span>
            </transition>
            <transition name="fade">
                <span
                    v-if="isDummyUser"
                    class="pill info light mleft-xsmall"
                >Dummy User</span>
            </transition>
        </div>

        <div class="meta email">
            <a
                :href="`mailto:${user.email}`"
                target="_blank"
                rel="noopener noreferrer"
            >{{ user.email }}</a>
        </div>

        <v-popover
            v-if="isAdmin"
            hide-on-target-click="false"
            popover-class="behind-modal"
        >
            <div class="popover-trigger">
                <icon name="more-horizontal" />
            </div>

            <template
                slot="popover"
                class="behind-modal"
            >
                <div style="min-height:40px">
                    <transition name="fade">
                        <UserSettings
                            :user="user"
                            @rolesChanged="user.roles = $event"
                        />
                    </transition>
                </div>
            </template>
        </v-popover>
        <UserRemoveModal
            :user="user"
            @delete="triggerDelete"
        />
    </div>
</template>

<script>
import Vue from "vue";
import Icon from "@web/components/Icon.vue";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET_UID,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import UserSettings from "@web/views/intranet/directory/UserSettings.vue";
import UserRemoveModal from "@web/views/intranet/directory/UserRemoveModal";
import UserLink from "@/components/user/UserLink";

export default Vue.extend({
    name: "UserRow",
    components: { Icon, UserSettings, UserRemoveModal, UserLink },
    props: {
        user: {
            type: Object,
        }
    },
    data() {
        return {
            startDeleting: false,
            dummyUserUids: [
                "6WJIdNg14ObbiPnjnmSQto7MTlB3",
                "2w3HCCq6NQQndxVGkRKA5wXNlle2",
                "wBiu016Z59OJpxzAAkFz04Zk8HB2",
            ],
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
        }),
        isDummyUser() {
            return this.dummyUserUids.includes(this.user.uid);
        },
        hasAdminPermissions() {
            if (!this.user.roles) {
                return false;
            }
            return this.user.roles.includes("admin");
        },
        classObject() {
            return {
                "collapsible": this.isAdmin,
                "not-collapsible": !this.isAdmin,
                "start-deleting": this.startDeleting,
            };
        }
    },
    methods: {
        triggerDelete(user) {
            this.startDeleting = true;
            this.$emit("delete", user);
        }
    },
});
</script>

<style lang="scss" scoped>
    .user-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: flex-start;
        align-items: center;

        border-bottom: 1px solid rgba(black, 0.1);
        padding: 10px 0;

        &.start-deleting > * {
            opacity: $opacity-twenty;
        }

        .avatar,
        .display-name,
        .email {
        }
        .avatar {
            margin: 0 $spacing-small 0 0;
            flex: 0 0 auto;
        }
        .display-name {
            margin: 0 $spacing-medium 0 0;
            width: 12.5rem;
            flex: 4 0 auto;
        }
        .email {
            width: 12.5rem;
            margin: 0 $spacing-medium 0 0;
            flex: 3 0 auto;
        }

        .join-date {
            width: 6.25rem;
            margin: 0 $spacing-medium 0 0;
            flex: 3 0 auto;
        }

        .meta a {
            text-decoration: none;
            vertical-align: text-bottom;
        }
    }
</style>

<style lang="scss">
    .user-row {
        .v-popover {
            .trigger {
                cursor: pointer;
            }
        }
    }
</style>
