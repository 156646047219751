<template>
    <Modal
        :name="'remove-user-modal-' + user.uid"
        warning
        unavoidable
    >
        <h3>{{ $t("remove_user") }}</h3>
        {{ $t('remove_user_confirm_descr', [user.displayName]) }}
        <template v-slot:footer>
            <div class="grid">
                <div class="left" ></div>
                <div class="right">
                    <div class="button-group">
                        <app-button
                            :busy="deleting"
                            type="warning"
                            @click="onDeleteBtnClick"
                        >
                            {{ $t("remove_user_confirm") }}
                        </app-button>
                        <app-button
                            :class="{disabled: deleting}"
                            @click="$modal.hide('remove-user-modal-' + user.uid)"
                        >
                            {{ $t("cancel") }}
                        </app-button>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Vue from "vue";
import { PEOPLE_MODULE_NAME } from "@web/store/people/people";
import { REMOVE_USER } from "@web/store/people/actions";
import { mapActions } from "vuex";
import Modal from "@web/components/modals/Modal";

export default Vue.extend({
    name: "UserRemoveModal",
    components: { Modal },
    props: {
        user: {
            type: Object
        },
    },
    data() {
        return {
            deleting: false,
        };
    },
    methods: {
        ...mapActions({ deleteUser: PEOPLE_MODULE_NAME + REMOVE_USER }),
        async onDeleteBtnClick() {
            this.deleting = true;
            await this.$emit("delete", this.user);
            this.$modal.hide("remove-user-modal-" + this.user.uid);
        },
    },
});
</script>
