<template>
    <div class="permission-controls">
        <transition
            name="fade"
            mode="out-in"
        >
            <div>
                <div
                    v-if="$store.state.auth.currentUser.uid !== user.uid"
                    class="permission-controls-inner"
                >
                    <div class="role admin-role form-group">
                        <input
                            id="admin-checkbox"
                            v-model="newIsAdmin"
                            type="checkbox"
                            name="admin-checkbox"
                            value="admin"
                            :disabled="updating"
                            @change="changeAdminRole"
                        />
                        <label
                            for="admin-checkbox"
                            :disabled="updating"
                        >{{ $t("administrator") }}</label>
                        <Spinner v-if="updating" />
                    </div>
                    <div class="form-group">
                        <app-button @click="$modal.show('remove-user-modal-' + user.uid)">
                            {{ $t('remove_account_from_intranet') }}
                        </app-button>
                    </div>
                </div>
                <div
                    v-else
                    class="permission-controls-inner"
                >
                    <span>{{ $t('you_are_not_allowed_to_edit_yourself') }}</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from "vue";
import { PEOPLE_MODULE_NAME } from "@web/store/people/people";
import {
    mapActions,
    mapState
} from "vuex";
import { SET_PERMISSIONS_FOR_USER } from "@web/store/people/actions";
import Spinner from "@web/components/Spinner.vue";
import { IntranetRole } from "@backend/intranet/types";

export default Vue.extend({
    name: "UserSettings",
    components: { Spinner },
    props: {
        user: {
            type: Object
        },
    },
    data() {
        return {
            isAdmin: false,
            newIsAdmin: false,
            updating: false,
        };
    },
    computed: {
        ...mapState(PEOPLE_MODULE_NAME, ["users"]),
    },
    methods: {
        ...mapActions({
            savePermissions: PEOPLE_MODULE_NAME + SET_PERMISSIONS_FOR_USER,
        }),
        async changeAdminRole() {
            this.updating = true;
            const roles = this.newIsAdmin ? [IntranetRole.user, IntranetRole.admin] : [IntranetRole.user];
            await this.savePermissions({
                userUid: this.user.uid,
                intranetPermissions: { roles }
            });
            this.$emit("rolesChanged", roles);
            this.updating = false;
        },
    },
    mounted() {
        this.isAdmin = this.user.roles.includes(IntranetRole.admin);
        this.newIsAdmin = this.isAdmin;
    }
});
</script>

<style lang="scss" scoped>
    .role {
        margin: 0 0 0.5rem 0;
        display:flex;
    }

    .form-group:last-of-type {
        margin: 1rem 0 0;
    }

</style>
