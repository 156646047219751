<template>
    <div>
        <div v-if="isAdmin">
            <modal
                :unavoidable="true"
                fullscreen
                name="admin-subscription-expired-lock"
            >
                <div class="pleft-large pright-large ptop-medium">
                    <router-link :to="{ name: 'login'}">
                        <everest-button
                            type="link primary"
                            class="back-to-login"
                        >
                            <h2>Back to login</h2>
                        </everest-button>
                    </router-link>
                    <AccountSettings
                        class="admin-subscription-expired-lock"
                        :show-people-management="false"
                    />
                </div>
            </modal>
        </div>
        <div v-else>
            <modal
                :unavoidable="true"
                fullscreen
                name="user-subscription-expired-lock"
            >
                <div class="user-subscription-expired-lock">
                </div>
                <system-message
                    class="lock-message"
                    asset="locked"
                    show-call-to-action
                    :title="userLockTitle"
                    button-text="Back to login"
                    @click="goToOverview"
                >
                    <v-popover
                        ref="popover"
                        popover-class="contact-list-popover"
                        :auto-hide="!visible"
                        :open="visible"
                        offset="0"
                        placement="center-center"
                        :boundaries-element="$el"
                        @show="addClickListener"
                        @hide="removeClickListener"
                    >
                        <div class="mbottom-small">
                            {{ userLockText }}
                            <icon
                                name="mail"
                                size="small"
                                class="cursor-pointer mail-icon"
                            />
                        </div>
                        <template #popover>
                            <h2>{{ $t("subscription_contact_list") }}</h2>
                            <user-row
                                v-for="userProfile in contactProfiles"
                                :key="userProfile.uid"
                                :user="userProfile"
                            />
                        </template>
                    </v-popover>
                </system-message>
            </modal>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import {
    CONTACT_UIDS,
    INTRANET,
    INTRANET_UID,
    IS_ADMIN,
    SUBSCRIPTION_STATUS,
} from "@/store/intranet/getters";
import Modal from "@/components/modals/Modal";
import AccountSettings from "@/views/intranet/admin/account/AccountSettings";
import { profileService } from "@/services";
import UserRow from "@/views/intranet/directory/UserRow";
import { ProductSubscriptionStatus } from "@backend/product-subscription/types";
import EverestButton from "@/components/Button";

export default {
    name: "SubscriptionExpiredScreen",
    components: {
        EverestButton,
        UserRow,
        AccountSettings,
        Modal,
    },
    data() {
        return {
            documentElement: document.documentElement,
            visible: false,
            contactProfiles: [],
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            contactUids: INTRANET_MODULE_NAME + CONTACT_UIDS,
            subscriptionStatus: INTRANET_MODULE_NAME + SUBSCRIPTION_STATUS,
        }),
        userLockTitle() {
            if (this.subscriptionStatus === ProductSubscriptionStatus.trialing) {
                return this.$t("subscription_trial_expiration_lock_title");
            }
            return this.$t("subscription_expired");
        },
        userLockText() {
            if (this.subscriptionStatus === ProductSubscriptionStatus.trialing) {
                return this.$t("subscription_trial_expiration_lock_text");
            }
            return this.$t("subscription_expiration_text", [this.intranet.displayName]);
        },
    },
    destroyed() {
        this.removeClickListener();
    },
    mounted() {
        this.buildContactList();
        if (this.isAdmin) {
            this.$modal.show("admin-subscription-expired-lock");
        } else {
            this.$modal.show("user-subscription-expired-lock");
        }
    },
    methods: {
        async buildContactList() {
            const profilePromises = this.contactUids.map(userUid => profileService.getFullUserProfile(this.intranetUid, userUid));
            this.contactProfiles = await Promise.allSettled(profilePromises)
                .then(results =>
                    [...results].filter(result => result.status === "fulfilled")
                        .map(result => result.value)
                );
        },
        goToOverview() {
            this.$router.push("/");
        },
        clickOutsideEvent(event) {
            const contactListEl = document.querySelector(".contactList");
            if (!contactListEl) return;
            if (!(contactListEl === event.target || contactListEl.contains(event.target))) {
                this.visible = false;
            }
        },
        addClickListener() {
            document.body.addEventListener("click", this.clickOutsideEvent);
        },
        removeClickListener() {
            document.body.removeEventListener("click", this.clickOutsideEvent);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-subscription-expired-lock{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url("~@web/assets/splash_screen/lock-background.png") center/cover no-repeat;
}

.lock-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.mail-icon {
    vertical-align: middle;
}

.user-row:last-of-type {
    border-bottom: none;
}

.back-to-login {
    padding: 0;
}
</style>

<style lang="scss">
.contact-list-popover {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%,-50%) !important;
}
</style>
